import React from "react";

const TelegramIcon = ({ width = "22", height = "18", fill = "#FFFFFF", ...props }) => {
    return (
        <div {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.45564 0.0241633C6.94381 0.0416662 4.54114 1.05326 2.77323 2.83766C1.00531 4.62205 0.0160549 7.034 0.0218631 9.54589C0.0276615 12.0654 1.03411 14.4795 2.8198 16.257C4.60549 18.0345 7.02415 19.0298 9.5437 19.024C12.0633 19.0182 14.4773 18.0117 16.2548 16.2261C18.0323 14.4404 19.0276 12.0217 19.0218 9.50216C19.016 6.98261 18.0096 4.56856 16.2239 2.79108C14.4382 1.01359 12.0195 0.0182628 9.49998 0.0240613C9.4852 0.0240608 9.47042 0.0240948 9.45564 0.0241633ZM13.397 5.7341C13.4762 5.73233 13.6512 5.75172 13.7654 5.84409C13.8413 5.9097 13.8898 6.0014 13.9014 6.10107C13.9142 6.17466 13.9304 6.34325 13.9181 6.47469C13.779 7.9776 13.1683 11.6238 12.8571 13.3069C12.7258 14.0197 12.4643 14.2585 12.2102 14.2821C11.6593 14.3348 11.2396 13.9202 10.7044 13.5715C9.86715 13.0248 9.39374 12.6847 8.58105 12.1514C7.6415 11.536 8.24872 11.1942 8.78182 10.6388C8.92161 10.4928 11.3469 8.27611 11.394 8.07571C11.3995 8.05037 11.4048 7.95694 11.3492 7.90798C11.2937 7.85903 11.2114 7.87584 11.1521 7.88944C11.0682 7.90863 9.7347 8.7952 7.15156 10.5468C6.77216 10.8089 6.42966 10.9364 6.12169 10.9291C5.78284 10.9236 5.13008 10.7406 4.64443 10.5842C4.04865 10.3916 3.57579 10.2906 3.61621 9.96195C3.63719 9.7909 3.8727 9.6154 4.32195 9.43545C7.08842 8.22258 8.93275 7.42271 9.85653 7.03662C12.4918 5.93331 13.04 5.74126 13.397 5.7341Z" fill={fill} />
            </svg>
        </div>
    )
}

export default TelegramIcon