import React from "react";
// import ChainIcon from "../../assets/icon/chain_icon";
// import FacebookIcon from "../../assets/icon/facebook_icon";
// import InstagramIcon from "../../assets/icon/instagram_icon";
// import TwitterIcon from "../../assets/icon/twitter_icon";
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    TelegramIcon,
    TelegramShareButton,
} from "react-share"
import { useParams } from "react-router-dom";
import { WEB_HOST } from "../../utils/helper";
const ShareLinkWidget = ({assetId}) => {
    const params = useParams()

    return (
        <div className="grid grid-cols-4 gap-2 justify-start">
            <FacebookShareButton
                url={`${WEB_HOST}/asset/${assetId ? assetId : params?.id}`}
                /* quote={"Hi"} */
                className="self-center cursor-pointer"
            >
                <FacebookIcon className="self-center cursor-pointer h-8 w-8 rounded-full" />
            </FacebookShareButton>
            <TwitterShareButton
                // title={"test"}
                url={`${WEB_HOST}/asset/${assetId ? assetId : params?.id}`}
                // hashtags={["hashtag1", "hashtag2"]}
            >
                <TwitterIcon className="self-center cursor-pointer h-8 w-8 rounded-full" />
            </TwitterShareButton>
            <WhatsappShareButton
                title="NFT Marketplace"
                url={`${WEB_HOST}/asset/${assetId ? assetId :params?.id}`}
            >
                <WhatsappIcon className="self-center cursor-pointer h-8 w-8 rounded-full" />
            </WhatsappShareButton>
            <TelegramShareButton
                title="NFT Marketplace"
                url={`${WEB_HOST}/asset/${assetId ? assetId : params?.id}`}
            >
                <TelegramIcon className="self-center cursor-pointer h-8 w-8 rounded-full" />
            </TelegramShareButton>
            {/* <FacebookIcon className="self-center cursor-pointer" />
            <InstagramIcon className="self-center cursor-pointer" />
            <TwitterIcon className="self-center cursor-pointer" />
            <ChainIcon className="self-center cursor-pointer" /> */}
        </div>
    )
}

export default ShareLinkWidget