import React, { useContext } from 'react'
import FacebookIcon from '../../assets/icon/facebook_icon'
import InstagramIcon from '../../assets/icon/instagram_icon'
import TelegramIcon from '../../assets/icon/telegram_icon'
import TwitterIcon from '../../assets/icon/twitter_icon'
import ShareLinkWidget from '../../component/Widget/ShareLinkWidget'
import ResponsiveContext from '../../context/Responsive/responsiveContext'

const FooterMobile = () => {
  const layananInfo = [
    {
      title: 'Syarat Layanan',
      url: '/syarat-ketentuan',
    },
    {
      title: 'Kebijakan Privasi',
      url: '/kebijakan-privasi',
    },
  ]
  const bantuan = [
    {
      title: 'FAQ',
      url: '/faq',
    },
    {
      title: 'Tentang Kami',
      url: '',
    },
  ]
  return (
    <div className="flex flex-col bg-hijau_muda_pudar px-6 py-8">
      <div className="pb-6 border-b">
        <p className="font-poppins text-sm font-semibold text-hijau_misty mb-2">
          NFT Saya
        </p>
        <p className="font-poppins text-xs font-medium text-teks_2">
          Unggah aset yang kamu buat seperti Gambar, video, musik untuk
          dikonversi menjadi NFT
        </p>
      </div>
      <div className="flex flex-row my-6 gap-x-8">
        <div>
          <p className="font-poppins font-semibold text-teks text-xs mb-3">
            Layanan Informasi
          </p>
          <div className="flex flex-col gap-y-2">
            {layananInfo.map(({ title, url }, idx) => (
              <a key={idx} href={url}>
                <p className="font-poppins font-medium text-teks_2 text-xs">
                  {title}
                </p>
              </a>
            ))}
          </div>
        </div>
        <div>
          <p className="font-poppins font-semibold text-teks text-xs mb-3">
            Bantuan
          </p>
          <div className="flex flex-col gap-y-2">
            {bantuan.map(({ title, url }, idx) => (
              <a key={idx} href={url}>
                <p className="font-poppins font-medium text-teks_2 text-xs">
                  {title}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="w-3/5">
        <p className="font-poppins font-semibold text-black text-xs mb-3">
          Ikuti Kami
        </p>
        <ShareLinkWidget />
      </div>
    </div>
  )
}

const Footer = () => {
  const responsive = useContext(ResponsiveContext)
  return !responsive ? (
    <div className="inline-flex justify-between w-full bg-footer py-32 pr-20">
      <div className="flex flex-col px-16 w-2/5">
        <div className="inline-flex w-full items-center">
          <p className="text-hijau_hutan text-2xl font-poppins font-semibold mr-4 mb-2">
            NFT Saya
          </p>
          {/* <hr className="border-solid border-gray-300  space-y-5w-full" /> */}
        </div>
        <p className="text-white font-poppins font-medium text-sm">
          Tempat terbaik jual beli aset NFT, tunggu apa lagi, ayo segera cari
          dan telusuri aset favoritmu
        </p>
      </div>
      <div className="flex flex-row">
        <div className="flex-col flex px-16 border-l-2 border-gray-300 space-y-5">
          <p className="text-base font-poppins font-semibold text-white">
            Layanan Informasi
          </p>
          <a href="/syarat-ketentuan">
            <p className="text-sm font-poppins font-normal text-white">
              Syarat dan Ketentuan
            </p>
          </a>
          <a href="/kebijakan-privasi">
            <p className="text-sm font-poppins font-normal text-white">
              Kebijakan Privasi
            </p>
          </a>
        </div>
        <div className="flex-col flex px-16 border-l-2 border-gray-300 space-y-5">
          <p className="text-base font-poppins font-semibold text-white">
            Bantuan
          </p>
          <a href="/faq">
            <p className="text-sm font-poppins font-normal text-white">FAQ</p>
          </a>
          <p className="text-sm font-poppins font-normal text-white">
            Tentang Kami
          </p>
        </div>
        <div className="flex-col flex px-16 border-l-2 border-gray-300 space-y-5">
          <p className="text-base font-poppins font-semibold text-white">
            Ikuti Kami
          </p>
          <div className="flex flex-row items-center space-x-2 text-white">
            <InstagramIcon />
            <p className="text-base font-poppins font-normal">Instagram</p>
          </div>
          <div className="flex flex-row  items-center space-x-2 text-white">
            <FacebookIcon />
            <p className="text-base font-poppins font-normal">Facebook</p>
          </div>
          <div className="flex flex-row  items-center space-x-2 text-white">
            <TwitterIcon />
            <p className="text-base font-poppins font-normal">Twitter</p>
          </div>
          <div className="flex flex-row  items-center space-x-2 text-white">
            <TelegramIcon />
            <p className="text-base font-poppins font-normal">Telegram</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <FooterMobile />
  )
}

export default Footer
